@import "base/reset.scss";
@import "base/typography.scss";

body {
  background-color: black;
}

img {
  padding-top: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: auto;
  margin: auto;
}

h2 {
  color: white;
  padding-top: 2rem;
  text-align: center;
  font-weight: 200;
  font-size: 1rem;
}
